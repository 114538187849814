export class SubDepartamentos implements ISubDepartamentos {
  id: number;  
  idDepartamentoEmpleado: number; 
  nombre: string;
}
export interface ISubDepartamentos {
  id: number;
  idDepartamentoEmpleado: number;  
  nombre: string;
}
