import { Component, OnInit, OnDestroy, inject, TemplateRef, ViewChild, ViewContainerRef, effect, Input } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SplusBaseForm } from '@src/app/models/base/BaseFormComponent';
import { HttpErrorResponse } from "@angular/common/http";

import {
  ProcessType,
  SPlusDialogCloseResult,
  SPlusDialogSettings,
  SPlusFormsDialogsService,
  SPlusMoreOption,
  SPlusOption,
} from "@saludplus/forms";

import { SubDepartamentosService } from "../services/sub-departamentos.service";
import { SubDepartamentosSettings } from "../config/sub-departamentos.settings";
import { SubDepartamentos } from "@src/app/models/Nomina/SubDepartamentos.model";
import { DepartamentosSearch } from "../../departamentos/config/departamentos.search";
import { Departamentos } from "@src/app/models/Nomina/Departamentos.model";
import { FiltersData } from "@src/app/models/base/filterModel";
import { DepartamentosService } from "../../departamentos/services/departamentos.service";
import { SearchInListStore } from "@src/app/core/Search/search-in-list/services/search-in-list.store";
import { CommonModule } from "@angular/common";
import { ComponentsCoreFormsModule, DirectivesCoreFormsModule } from "@src/app/components/Core/core.saludplus.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { KENDO_BUTTONS } from "@progress/kendo-angular-buttons";
import { KENDO_DROPDOWNS } from "@progress/kendo-angular-dropdowns";
import { KENDO_INPUTS } from "@progress/kendo-angular-inputs";
import { KENDO_INDICATORS } from "@progress/kendo-angular-indicators";
import { SubDepartamentoStore } from "../services/sub-departamento.store";
import { SubDepartamentosSearch } from "../config/sub-departamentos.search";
import { RoutingState } from "@src/app/services/Generals/routing.state.service";

@Component({
    selector: "app-sub-departamentos",
    templateUrl: "./sub-departamentos.component.html",
    styleUrls: ["./sub-departamentos.component.scss"],
    imports: [
        CommonModule,
        ComponentsCoreFormsModule,
        DirectivesCoreFormsModule,
        FormsModule,
        ReactiveFormsModule,
        /* ,
         SubDepartamentosRoutingModule,
      
         MainPipe,
         //kendo */
        KENDO_INPUTS,
        KENDO_BUTTONS,
        KENDO_DROPDOWNS,
        KENDO_INDICATORS
    ],
    providers: []
})
export class SubDepartamentosComponent extends SplusBaseForm implements OnInit, OnDestroy {

  // Forms   
  departamentos: Departamentos[] = [];
  Data = new SubDepartamentos();

  //listado de opciones --
  options: SPlusOption[] = [
    {
      id: "Listado",
      icon: "fa-solid fa-magnifying-glass",
      text: "Listado",
      link: "/nomina/sub-departamentos/listado",
    },
    {
      id: "Ayuda",
      icon: "fa-solid fa-circle-question",
      text: "Ayuda",
      action: () => {
        this.OpenDocumentacion();
      },
    },
  ];

  //listado de más opciones --
  moreOptions: SPlusMoreOption[] = [
    {
      id: "CrearSubDepartamentos",
      showIn: ProcessType.view,
      text: "Crear nuevo sub departamento",
      icon: "fa-duotone fa-plus",
      action: () => this.newRecord(),
    },
    {
      id: "EliminarSubDepartamento",
      showIn: ProcessType.edit,
      text: "Eliminar",
      icon: "fa-solid fa-trash-can",
      action: () => this.dialogEliminar(),
    },
  ];

  private unsubcribe$ = new Subject<void>();


  private service = inject(SubDepartamentosService);
  private departamentosService = inject(DepartamentosService);
  private settings = inject(SubDepartamentosSettings);
  private searchInListStore = inject(SearchInListStore);

  departamentosSearch = DepartamentosSearch

  @ViewChild("container", { read: ViewContainerRef }) public containerRef: ViewContainerRef;
  closePop: boolean = false;

  filtersDepartamentos: FiltersData[] = [];

  subDepartamentoStore = inject(SubDepartamentoStore);

  //departamento 
  @Input() parentId: number;

  constructor() {
    super()

    this.filtersDepartamentos = [
      {
        propertyName: "selectionPopup",
        propertyValue: true,
      }
    ];

    /*effect(() => {
      var subValue = this.subDepartamentoStore.addSubDepartamento();
      this.id = subValue.subDepartamentoId;

      this.After();

    })*/

  }

  ngOnInit() {

    this.id = this.route.snapshot.params['id'];
    this.title = this.route.snapshot.data['title'];
    this.icon = this.route.snapshot.data['icon'];
    this.titleService.setTitle(this.title);

    this.FormRecord = this.settings.FormComponents();


    this.FormRecord.disable();
 
    this.After(); 
  }


  /**
   * Abreviacion para acceder a los controles del FormRecord
   */
  public get f(): any {
    return this.FormRecord.controls;
  }

  ngOnDestroy() {
    this.unsubcribe$.next();
    this.unsubcribe$.complete();

    this.searchInListStore.setSearchInListSearch(false);
  }

  /**
   * Guardar o actualizar el registro
   */
  async onSubmit(event) {
    event.preventDefault();
    this.submitted = true;
    if (this.FormRecord.valid) {
      if (this.FormRecord.pristine) return;
      this.isLoading = true;
      if (this.processType === this.ProcessType.edit) {
        this.UpdateRecord();
      } else {
        this.SaveRecord();
      }
    } else {
      this.isLoading = false;
    }
  }

  /**
   * Guardar
   */
  SaveRecord() {
    this.service
      .Guardar<number, SubDepartamentos>(this.FormRecord.value)
      .subscribe({
        next: (res) => {
          this.isLoading = false;
          if (res) {
            this.router.navigate([`${res}`], {
              queryParams: { mode: "i" },
              relativeTo: this.route,
            });

          }

        }
      });
  }

  /**
   * Actualizar
   */
  UpdateRecord() {
    this.isLoading = true;
    this.submitted = true;
    if (this.FormRecord.valid && !this.FormRecord.pristine) {
      let data = this.FormRecord.value as SubDepartamentos;
      data.id = this.id;
      this.service
        .Actualizar<boolean, SubDepartamentos>(data)
        .subscribe({
          next: (res) => {
            this.isLoading = false;
            if (res) {
              this.router.navigate([], {
                queryParams: { mode: "u" },
                queryParamsHandling: "merge",
              });
            }


          }
        });
    } else {
      this.isLoading = false;
    }
  }
  /**
   * Eliminar
   */
  DeleteRecord() {

    this.isDeleting = true;
    this.isLoading = true;
    const userSearchParams = {
      id: this.id
    };
    this.service.Eliminar<boolean>(userSearchParams).subscribe({
      next: (res) => {
        if (res) {
          this.isDeleting = false;

          if (this.loadSource == 'tab') {
            this.searchInListStore.setSearchInListSearch(true);
          }
          else {
            this.router.navigate(["../"], {
              queryParams: { mode: "d" },
              queryParamsHandling: "merge",
              relativeTo: this.route,
            });
          }

        }

      }
    });
  }

  /**
   * Abre alerta para eliminar registro
   */
  dialogEliminar() {
    let dialogSettings = {} as SPlusDialogSettings;
    dialogSettings.title = "Eliminar sub departamento";
    dialogSettings.description =
      "¿Estas seguro que desea eliminar este sub departamento?";
    dialogSettings.actions = [
      { text: "Cancelar" },
      { text: "Si, Eliminar", themeColor: "warning", value: true },
    ];
    this.splusFormsDialogsService
      .Show(dialogSettings)
      .pipe(takeUntil(this.unsubcribe$))
      .subscribe((result) => {
        if (result instanceof SPlusDialogCloseResult) {
          console.log("close", result);
        } else {
          if (result.value) {
            this.DeleteRecord();
          }
        }
      });
  }

 
  /**
   * Limpiar formulario
   */
  clearForm() {
    this.FormRecord.reset();
    this.FormRecord.updateValueAndValidity();
    // Valores por defecto
    this.SetDefaultValues();
    this.submitted = false;
  }
  /**
   * Setea valores por defecto
   */
  SetDefaultValues() {
    //if (!this.parentId) return;
    //this.f.idDepartamentoEmpleado.setValue(this.parentId);
  }

  /**
   * formulario en modo edicción
   */
  goEdit() {
    this.routingState.goEdit();
  }

  /**
   * Cancelar insercion o edición
   */
  cancelEdit() {
    if (this.processType === ProcessType.create) {
      this.goback();
    } else if (this.processType === ProcessType.edit) {
      // validar si se modificó el formulario visualmente
      if (!this.FormRecord.pristine) {
        let dialogSettings = {} as SPlusDialogSettings;
        dialogSettings.title = "Cancelar edición";
        dialogSettings.isHtml = true;
        dialogSettings.description =
          "Hay cambios pendientes por guardar. <br/>¿Estás seguro que desea cancelar la edición?";
        dialogSettings.actions = [
          { text: "Seguir Editando" },
          { text: "Si, Cancelar", themeColor: "primary", value: true },
        ];
        this.splusFormsDialogsService
          .Show(dialogSettings)
          .pipe(takeUntil(this.unsubcribe$))
          .subscribe((result) => {
            if (result instanceof SPlusDialogCloseResult) {
              console.log("close", result);
            } else {
              if (result.value) {
                this.cancelUpdate();
              }
            }
          });
      } else {
        this.goback();
      }
    }
  }



  /**
   * Cargar el registro por su id
   */
  LoadRecord() {

    this.isLoading = true;
    const userSearchParams = {
      id: this.id
    };
    this.service.Buscar<SubDepartamentos>(userSearchParams).subscribe({
      next: (res) => {
        this.isDeleting = false;
        if (res) {

          this.Data = res;
          this.FormRecord.patchValue(this.Data);
        }
      },
      error: (err) => {
        if (err instanceof HttpErrorResponse && err.status == 401) return;
        console.warn("Error critico de lado del cliente", err);
        let dialogSettings = {} as SPlusDialogSettings;
        dialogSettings.title = "Cargar sub departamentos";
        dialogSettings.description =
          "Hubo un error al intentar cargar el sub departamento por favor intentelo más tarde";
        dialogSettings.actions = [{ text: "Cerrar" }];
        this.splusFormsDialogsService.Show(dialogSettings);
      },
    });
  }



  After() {
    if (!this.FormRecord) return;

    this.FormRecord.enable();

    if (this.id) {
      this.LoadRecord();
    } else {
      this.SetDefaultValues();
    }
    this.isLoading = false;
  }


  OpenDiagBuscarDepartamentos(template: TemplateRef<unknown>): void {
    this.closePop = false;
    let dialogSettings = {} as SPlusDialogSettings;
    dialogSettings.content = template;
    dialogSettings.appendTo = this.containerRef;
    dialogSettings.width = "70%";
    dialogSettings.title = "Listado Departamentos";
    dialogSettings.actions = [{ text: "Cerrar" }]
    dialogSettings.actionsLayout = "end"
    this.splusFormsDialogsService.ShowCustom(dialogSettings)
  }

  GetSelectionDepartamento(data: any) {
    let datos = {
      id: data.id,
      nombre: data.nombre
    } as Departamentos;
    this.departamentos = [];
    this.departamentos.push(datos);
    this.f.idDepartamentoEmpleado.setValue(datos.id);

    this.closePop = true;
    setTimeout(() => {
      this.closePop = false;
    }, 500)

  }


  async Filtrar(search: string) {
    this.departamentos = [];
    let emp = await this.departamentosService.CargarDepartamentos<any[]>(search);

    this.departamentos = emp.map(s => {
      let datos = {
        id: s.id,
        nombre: s.nombre
      } as Departamentos;
      return datos;
    });

  }


  /**
   * Cancelar edicion
   */
  cancelUpdate() {
    this.FormRecord.patchValue(this.Data);
    this.FormRecord.markAsPristine();
    this.router.navigate([], {
      queryParams: { mode: null },
      queryParamsHandling: "merge",
    });
  }


}