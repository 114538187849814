import { Router, ActivatedRoute } from "@angular/router";
import { FormSearch } from "src/app/models/base/FormSearch";
import { Component, inject } from "@angular/core";
import { SearchTypeFilter } from "@src/app/models/base/Search/SearchType";
import {
  MultipleFilter,
} from "@src/app/models/Tegett/SimpleFilterData";
import { ControllerApiList } from "@src/app/models/SaludPlus/Controller/ControllerList";
import { FiltersRecently } from "@src/app/models/base/FiltersRecently";
import { BaseSearchSetting } from "@src/app/models/base/BaseSearchSetting";
import { EndPointMethods, FiltersData } from "@src/app/models/base/filterModel";
import { DepartamentosSettings } from "./departamentos.settings";

@Component({
    selector: "app-departamentosSearch",
    template: "",
    standalone: false
})
export class DepartamentosSearch implements BaseSearchSetting {

  tiposDocumentos: MultipleFilter;
  selectionPopup = false;
 
  private router = inject(Router)
  private route = inject(ActivatedRoute)
  private settings = inject(DepartamentosSettings)

  constructor() { }

  /**
  * Funcion para armar la busqueda
  */
  loadFormSearch(): FormSearch {
    const departamentosSearch = new FormSearch();

    departamentosSearch.formComponents = this.settings.FormComponents()
    departamentosSearch.configSearch = {
      title: "Departamentos",
      titleIcon: "fa-sharp fa-light fa-list-timeline",
      apiSearch: ControllerApiList.Nomina.Departamentos,
      apiSearchResult: "Listado",
      apiSearchVersion: 3,
      filtersRecently: this.LoadSimpleFilter(),
      buttonNewRecordText: "Nuevo Departamento",
      MethodRequest: EndPointMethods.GET,
      buttonNewRecordClick: () => this.newRecord(),

      settings: [ 
        { objectKey: "id", sortV3: "desc", visible: false },
      ],
      fields: [
        {
          renderLabel: "ID",
          objectKey: "id",
          autoSize: true,
        },
        {
          renderLabel: "Nombre",
          objectKey: "nombre",
          module: "empleados",
          pipe: "ColumnCustom", icon: "inherit",
          click: (row) => this.goView(row),
          autoSize: true,
          selectionPopup: this.selectionPopup,
        }, 
       
      ],
      filters: [ 
        { objectKey: "nombre", type: SearchTypeFilter.Text },
      ],
    };
    return departamentosSearch;
  }


  async Load(custom: FiltersData[] = []) {

    const popUp = custom.find(s => s.propertyName = "selectionPopup");
    if (popUp) {
      this.selectionPopup = popUp.propertyValue;
    }
    
    return true;
  }

 
 


  newRecord() {
    this.router.navigate(["../"], { relativeTo: this.route });
  }

  goEdit(id: number) {
    this.router.navigate(["../", id], {
      queryParams: { mode: "e" },
      queryParamsHandling: "merge",
      relativeTo: this.route,
    });
  }
 
  /**
   * ir al registro
   * @param row linea proveniente de la busqueda
   */
  goView(row: any) {
    if (row.id) {
      this.router.navigate(["../", row.id], { relativeTo: this.route });
    }
  }
 
 

  LoadSimpleFilter(): FiltersRecently[] {
    let filterdataRecently: FiltersRecently[] = [
      {
        field: 0,
        text: "Mis Actividades",
        separator: true,
        value: "1"
      },
      {
        field: 1,
        text: "Actualizado recientemente",
        value: "2"
      },
      {
        field: 2,
        text: "Creado recientemente",
        isDefault: true,
        value: "3"
      }
    ];
    return filterdataRecently
  }
}
