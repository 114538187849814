<splus-forms id="DepartamentosPage" [submit]="submitted" [messageProcess]="messageProcess"
  [showMessageProcess]="showMessageProcess" [FormRecord]="FormRecord">

  <form [formGroup]="FormRecord" (ngSubmit)="onSubmit($event)" autocomplete="off"
    [ngClass]="{'formGroup-read': processType == ProcessType.view}">

    <splus-forms-header [isLoading]="isLoading">

      <splus-forms-main-title [title]="title" [icon]="icon"> </splus-forms-main-title>


      <splus-forms-toolbar [options]="options">
        <splus-forms-toolbar-buttons [moreOptions]="moreOptions" [processType]="processType">

          <button type="Submit" [disabled]="isLoading"
            *ngIf="processType == ProcessType.create || processType == ProcessType.edit" kendoButton rounded="small"
            themeColor="primary">
            <i class="fa-solid fa-floppy-disk"></i> Guardar
          </button>

          <button [disabled]="isLoading" type="button" *ngIf="processType == ProcessType.view" kendoButton
            rounded="small" themeColor="primary" (click)="goEdit()">
            <i class="fa-solid fa-pencil"></i> Editar
          </button>

          <button *ngIf="processType == ProcessType.edit" type="button" kendoButton rounded="small" themeColor="base"
            (click)="cancelEdit()">
            Cancelar
          </button>

          <button *ngIf="processType == ProcessType.view" type="button" kendoButton rounded="small" themeColor="base"
            (click)="goback()">
            Volver
          </button>

          <button *ngIf="processType == ProcessType.create" type="button" kendoButton rounded="small" themeColor="base"
            (click)="clearForm()">
            Limpiar
          </button>

        </splus-forms-toolbar-buttons>
      </splus-forms-toolbar>


    </splus-forms-header>

    <splus-forms-fields [processType]="processType">

      <splus-forms-group title="Información Básica" #separadorInformacionBasica>


        <splus-field *ngVar="f.nombre as ctrl" [splusFormControl]="ctrl">
          <kendo-textbox splusInput [splusGroup]="separadorInformacionBasica" type="text" [id]="ctrl.nameControl"
            [formControl]="ctrl">


            <ng-template kendoTextBoxSuffixTemplate>
              <div *ngIf="searchingnombre" class="k-i-loading w-5">&nbsp;</div>
            </ng-template>

          </kendo-textbox>
        </splus-field>


        <splus-field *ngVar="f.idDepartamentoEmpleado as ctrl" [splusFormControl]="ctrl">
          <kendo-combobox #select splusInput [splusGroup]="separadorInformacionBasica" [formControl]="ctrl"
            [valuePrimitive]="true" [data]="ctrl?.funtionData | async" [valueField]="'id'" [filterable]="true"
            [textField]="'nombre'" [allowCustom]="false" [clearButton]="select.isOpen">
          </kendo-combobox>

          <ng-template splusFieldViewModeTemplate>
            {{select["text"]}}
          </ng-template>
        </splus-field>


        <splus-field *ngVar="f.descripcion as ctrl" [splusFormControl]="ctrl">
          <kendo-textarea splusInput [splusGroup]="separadorInformacionBasica" type="text" [id]="ctrl.nameControl"
            [formControl]="ctrl">
          </kendo-textarea>
        </splus-field>


      </splus-forms-group>



    </splus-forms-fields>

  </form>
</splus-forms>

<ng-template #popBuscarDepartamentos class="fuente">
  <app-search-in-list [inPopup]="true" (SendDataEmitter)="GetSelectionDepartamento($event)"
    [search]="departamentosSearch" [filtersData]="filtersDepartamentos">
  </app-search-in-list>
</ng-template>
<ng-container #container *ngIf="!closePop"></ng-container>