import { Router, ActivatedRoute } from "@angular/router";
import { FormSearch } from "src/app/models/base/FormSearch";
import { Component, inject, Input } from "@angular/core";
import { SearchTypeFilter } from "@src/app/models/base/Search/SearchType";
import { ControllerApiList } from "@src/app/models/SaludPlus/Controller/ControllerList";
import { FiltersRecently } from "@src/app/models/base/FiltersRecently";
import { BaseSearchSetting } from "@src/app/models/base/BaseSearchSetting";
import { EndPointMethods, FiltersData } from "@src/app/models/base/filterModel";
import { SubDepartamentosSettings } from "./sub-departamentos.settings";
import { SubDepartamentosComponent } from "../components/sub-departamentos.component";
//import { SubDepartamentoStore } from "../services/sub-departamento.store"; 

@Component({
    selector: "app-sub-departamentosSearch",
    template: "",
    standalone: false
})
export class SubDepartamentosSearch implements BaseSearchSetting {

  selectionPopup = false;

  private router = inject(Router)
  private route = inject(ActivatedRoute)
  private subDepartamentosSettings = inject(SubDepartamentosSettings);
  //private subDepartamentoStore = inject(SubDepartamentoStore);

  static fields = [];
  static resizeColumn = true;
  filtersCustom: string;

  static settings: any = [
    { objectKey: "id", sortV3: "desc", visible: false },
  ]

  constructor() {
    //  this.parent.loadSource = 'tab';
  }

  /**
  * Funcion para armar la busqueda
  */
  loadFormSearch(): FormSearch {
    const dataSearch = new FormSearch();

    dataSearch.formComponents = this.subDepartamentosSettings.FormComponents();


    dataSearch.configSearch = {
      title: "Sub Departamentos",
      titleIcon: "fa-sharp fa-light fa-list-timeline",
      apiSearch: ControllerApiList.Nomina.SubDepartamentos,
      apiSearchResult: "Listado",
      apiSearchVersion: 3,
      filtersRecently: this.LoadSimpleFilter(),
      filtersCustom: this.filtersCustom,
      buttonNewRecordText: "Nuevo Sub Departamento",
      MethodRequest: EndPointMethods.GET,
      buttonNewRecordClick: () => this.newRecord(),
      settings: SubDepartamentosSearch.settings,
      resizeColumn: SubDepartamentosSearch.resizeColumn,
      fields: [
        {
          renderLabel: "ID",
          objectKey: "id",
          autoSize: true,
        },
        {
          renderLabel: "Nombre",
          objectKey: "nombre",
          module: "nomina",
          pipe: "ColumnCustom", icon: "inherit",
          click: (row) => this.goView(row),
          autoSize: true,
          //selectionPopup: this.selectionPopup,

          /* moreOptions: [
           {
              id: "EliminarSubDepartamento",
              text: "Eliminar",
              reloadSearch: true,
              action: (row) => this.ActionDelete(row),
            },
          ],*/
        },
        {
          renderLabel: "Departamento",
          objectKey: "departamento",   

        },

      ],
      filters: [
        { objectKey: "nombre", type: SearchTypeFilter.Text },
      ],


    };

    dataSearch.configSearch.fields.push(...SubDepartamentosSearch.fields);


    return dataSearch;
  }


  ActionDelete(row: any) {
    /* this.parent.id = row.id;
     this.parent.dialogEliminar();*/
  }


  async Load(custom: FiltersData[] = []) {
    //this.filtersCustom = this.GetFilterCustom(custom);
    return true;
  }

  GetFilterCustom(data: FiltersData[]) {
    if (data && data.length > 0) {
      var dataParam = {};
      data.forEach((element) => {
        dataParam[element.propertyName] = element.propertyValue;
      });
      const filter = JSON.stringify(dataParam);
      return filter;
    }

    return '';
  }




  newRecord() {
    /* if (this.parent.loadSource == 'tab') { 
     this.subDepartamentoStore.setSearchInListSearch({ subDepartamentoId: 0, departamentoId: 0 });
      this.subDepartamentoStore.setNewSubDepartamento(true);
    } else {*/
    this.router.navigate(["../"], { relativeTo: this.route });
    /*  }*/
  }

  goEdit(id: number) {
    this.router.navigate(["../", id], {
      queryParams: { mode: "e" },
      queryParamsHandling: "merge",
      relativeTo: this.route,
    });
  }

  /**
   * ir al registro
   * @param row linea proveniente de la busqueda
   */
  /*goView(row: any) {

let dataRow = {
  departamentoId: row.idDepartamentoEmpleado,
  subDepartamentoId: row.id
}

this.subDepartamentoStore.setSearchInListSearch(dataRow);
this.subDepartamentoStore.setNewSubDepartamento(true);
}*/
  goView(row: any) {
    if (row.id) {
      this.router.navigate(["../", row.id], { relativeTo: this.route });
    }
  }

  goViewDepartamento(row: any) {
    this.router.navigate(["../../departamentos/", row.idDepartamento], { relativeTo: this.route });
  }



  LoadSimpleFilter(): FiltersRecently[] {
    let filterdataRecently: FiltersRecently[] = [
      {
        field: 0,
        text: "Mis Actividades",
        separator: true,
        value: "1"
      },
      {
        field: 1,
        text: "Actualizado recientemente",
        value: "2"
      },
      {
        field: 2,
        text: "Creado recientemente",
        isDefault: true,
        value: "3"
      }
    ];
    return filterdataRecently
  }
}
