import { UntypedFormGroup, Validators } from "@angular/forms";
import {
  TegettFormControl,
  TypeField,
} from "src/app/models/Tegett/tegettFormControl";
import { FiltersRecently } from "@src/app/models/base/FiltersRecently";
import { inject } from "@angular/core";
import { SubDepartamentosService } from "../services/sub-departamentos.service";
import { DepartamentosService } from "../../departamentos/services/departamentos.service";

export class SubDepartamentosSettings {

  departamentoServices = inject(DepartamentosService);
  constructor() { }

  /**
   * Componentes del formulario
   */
  FormComponents(): UntypedFormGroup {
    const formRecord = new UntypedFormGroup(
      { 
        idDepartamentoEmpleado: new TegettFormControl({
          formState: "",
          validatorOrOpts: [Validators.required],
          asyncValidator: undefined,
          label: "Departamento",
          typeField: TypeField.List,
          funtionData: this.departamentoServices.ObteneridDepartamentos(),
          required: true,
          nameControl: "idDepartamentoEmpleado",
          dataTextField: 'nombre',
          dataValueField: 'id',

        }),


        nombre: new TegettFormControl({
          formState: "",
          validatorOrOpts: [Validators.required,],
          asyncValidator: undefined,
          label: "Nombre",
          typeField: TypeField.Input,
          funtionData: undefined,
          required: true,
          nameControl: "nombre",

        }),

        descripcion: new TegettFormControl({
          formState: "",
          validatorOrOpts: [], 
          label: "Descripción",
          typeField: TypeField.Input, 
          required: false,
          nameControl: "descripcion",
          
        }),
      }
      ,
      []
    );
    return formRecord;
  }



}
