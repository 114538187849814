import { Injectable } from "@angular/core";
import { HttpErrorResponse} from "@angular/common/http";
import { ControllerApiList } from "@app/models/SaludPlus/Controller/ControllerList";
import { SaludPlusApiResponse } from "@src/app/models/base/SaludplusApiResponse";
import { map, Observable } from "rxjs";
import { SplusBaseService } from "@src/app/services/Generals/splusBase.service";


@Injectable({
  providedIn: "root",
})
export class SubDepartamentosService extends SplusBaseService {
  static instance: SubDepartamentosService;
  error: HttpErrorResponse;

  constructor() {
    super();
    SubDepartamentosService.instance = this;
    this.nameEndPoint = `${ControllerApiList.Nomina.SubDepartamentos}`
  } 
 

}
