import { UntypedFormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { Component, inject } from "@angular/core";
import {
  TegettFormControl,
  TypeField,
} from "src/app/models/Tegett/tegettFormControl";
import Validation from "@src/app/components/Core/directives/generals/validatorError";
import { FiltersRecently } from "@src/app/models/base/FiltersRecently";
import { DepartamentosService } from "../services/departamentos.service";
 
export class DepartamentosSettings {
  
  private departamentosServices = inject(DepartamentosService);

  constructor() {}

  /**
   * Componentes del formulario
   */
  FormComponents(): UntypedFormGroup {
    const formRecord = new UntypedFormGroup(
      {
        
            nombre: new TegettFormControl({
              formState: "",
              validatorOrOpts: [Validators.required, ], 
              label: "Nombre",
              typeField: TypeField.Input, 
              required: true,
              nameControl: "nombre",
              
            }),
          
            descripcion: new TegettFormControl({
              formState: "",
              validatorOrOpts: [], 
              label: "Descripción",
              typeField: TypeField.Input, 
              required: false,
              nameControl: "descripcion",
              
            }),
          
      }
      ,
      []
    );
    return formRecord;
  }
   

  LoadSimpleFilter(): FiltersRecently[] {
    let filterdataRecently: FiltersRecently[] = [
      {
        field: 0,
        text: "Mis Actividades",
        separator: true,
        value: "1"
      },
      {
        field: 1,
        text: "Actualizado recientemente",
        value: "2"
      },
      {
        field: 2,
        text: "Creado recientemente",
        isDefault: true,
        value: "3"
      }
    ];
    return filterdataRecently
  }
}
