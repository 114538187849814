
import { Injectable, signal } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SubDepartamentoStore {

    
    constructor() { }
    
    addSubDepartamento = signal({ subDepartamentoId: 0, departamentoId: 0 });
    setSearchInListSearch(signalData: { subDepartamentoId: number, departamentoId: number }) {
        this.addSubDepartamento.set(signalData);
    }
    
    newSubDepartamento = signal(false);
    setNewSubDepartamento(signalData: boolean) {
        this.newSubDepartamento.set(signalData);
    }

}