import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpParams} from "@angular/common/http";
import { ControllerApiList } from "@app/models/SaludPlus/Controller/ControllerList";
import { SaludPlusApiResponse } from "@src/app/models/base/SaludplusApiResponse";
import { lastValueFrom, map, Observable } from "rxjs";
import { SplusBaseService } from "@src/app/services/Generals/splusBase.service";


@Injectable({
  providedIn: "root",
})
export class DepartamentosService extends SplusBaseService {
 
  static instance: DepartamentosService;
  error: HttpErrorResponse;

  constructor() {
    super();
    DepartamentosService.instance = this;
    this.nameEndPoint = `${ControllerApiList.Nomina.Departamentos}`
  } 


 
    ObteneridDepartamentos<T>(): Observable<T> {
 
      const record = this.http.get<SaludPlusApiResponse<T>>(
        `${ControllerApiList.Nomina.Departamentos}/Departamentos`
      ).pipe(map(response => {
        return this.resolveRequestResultService.resolve<T>(response);
      }));
      return record;
    }
  
 

  async CargarDepartamentos<T>(search: string): Promise<T> {
    var prop = "id, nombre";


    const fromObject =
    {
      "pageSize": 50,
      "pageNumber": 1,
      "properties": prop,
      "filter": search,
      "sort": "id",
    }

    let params = new HttpParams({ fromObject: fromObject });
    let request = this.http.get<SaludPlusApiResponse<T>>(`${ControllerApiList.Nomina.Departamentos}/Listado`, {
      params: params,
    }).pipe(map((s) => s.result));;

    let dataMap = await lastValueFrom(request);
    return dataMap;
  }


}
